import React, { useEffect } from "react";
import ModalBase from "../../../modal/ModalBase";
import { Avatar, Button, MenuItem, Stack, Typography } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectApiInput from "../../../input/SelectApiInput";
import NumberInput from "../../../input/NumberInput";
import { generateLinkImage } from "../../../../../utils/helpers";

interface IFormAdd {
  open: boolean;
  isEdit?: boolean;
  defaultValue?: any;
  add?: (data: any, isEdit: boolean) => void;
  handleClose: () => void;
}

const schema = yup.object<FieldValues>({
  vat_tu: yup
    .object()
    .typeError("Vui lòng chọn Sản phẩm")
    .required("Vui lòng chọn Sản phẩm"),
});

function FormAdd({ open, isEdit, defaultValue, add, handleClose }: IFormAdd) {
  const defaultData: { [key: string]: any } = {
    vat_tu: null,
    don_vi_tinh: null,
    sl_ton_ss: 0,
    sl_ton_tt: 0,
    sl_xuat: 0,
    tien_xuat_nt: 0,
  };
  const originData = defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        vat_tu: defaultValue.ma_vt
          ? {
              ma_vt: defaultValue.ma_vt,
              ten_vt: defaultValue.ten_vt,
              ma_dvt: defaultValue.ma_dvt,
              ten_dvt: defaultValue.ten_dvt,
            }
          : null,
        don_vi_tinh: defaultValue.ma_dvt
          ? {
              ma_dvt: defaultValue?.ma_dvt || "",
              ten_dvt: defaultValue?.ten_dvt || "",
            }
          : null,
      }
    : defaultData;
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const vatTu = watch("vat_tu");
  const slTonSoSach = watch("sl_ton_ss");
  const slTonThucTe = watch("sl_ton_tt");

  const generateDataAdd = (values: typeof defaultData) => {
    const { vat_tu, don_vi_tinh, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_dvt: don_vi_tinh?.ma_dvt || "",
      ten_dvt: don_vi_tinh?.ten_dvt || "",
      tk_vt: "1561",
    };
  };

  // handle save
  const handleSave = (values: any) => {
    const dataAdd = generateDataAdd(values);
    add?.(dataAdd, !!isEdit);
    handleClose?.();
  };

  useEffect(() => {
    setValue(
      "don_vi_tinh",
      !!vatTu ? { ma_dvt: vatTu.ma_dvt, ten_dvt: vatTu.ten_dvt } : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  useEffect(() => {
    setValue("sl_xuat", (slTonSoSach || 0) - (slTonThucTe || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slTonSoSach, slTonThucTe]);

  return (
    <ModalBase
      title={`${isEdit ? "Thêm" : "Sửa"} chi tiết`}
      open={open}
      handleClose={handleClose}
      width="500px"
      actions={[
        <Button
          key={1}
          onClick={handleSubmit(handleSave)}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu
        </Button>,
        <Button
          key={2}
          onClick={handleClose}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Stack gap={2} sx={{ p: 2 }}>
        <Controller
          control={control}
          name="vat_tu"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                labelWidth="25%"
                label="Sản phẩm"
                placeholder="Chọn Sản phẩm"
                value={value}
                onChange={onChange}
                apiCode="dmvt"
                searchFields={["ma_vt", "ten_vt"]}
                getOptionLabel={(option) => option.ten_vt}
                checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
                renderOption={(option, { isEqual, key, onClick }) => {
                  return (
                    <MenuItem
                      key={key}
                      onClick={onClick}
                      sx={{
                        gap: 1,
                        backgroundColor: isEqual ? "primary.500" : "",
                      }}
                    >
                      <Avatar
                        src={
                          option?.picture_thumb
                            ? generateLinkImage(option?.picture_thumb)
                            : ""
                        }
                        sx={{ borderRadius: "10px" }}
                      />
                      <Typography>
                        {option.ten_vt} ({option.ma_vt})
                      </Typography>
                    </MenuItem>
                  );
                }}
                errorMessage={errors?.vat_tu?.message as string}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="don_vi_tinh"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                readOnly
                labelWidth="25%"
                label="Đơn vị tính"
                placeholder="Đơn vị tính tự động theo Sản phẩm"
                value={value}
                onChange={onChange}
                apiCode="dmdvt"
                searchFields={["ma_dvt", "ten_dvt"]}
                getOptionLabel={(option) => option.ten_dvt || option?.ma_dvt}
                checkEqual={(value, option) => value?.ma_dvt === option?.ma_dvt}
                errorMessage={errors?.don_vi_tinh?.message as string}
              />
            );
          }}
        />
        <NumberInput
          label="Tồn sổ sách"
          labelWidth="25%"
          name="sl_ton_ss"
          register={register}
        />
        <NumberInput
          label="Tồn thực tế"
          labelWidth="25%"
          name="sl_ton_tt"
          register={register}
        />
        <NumberInput
          label="Chênh lệch"
          labelWidth="25%"
          name="sl_xuat"
          register={register}
        />
      </Stack>
    </ModalBase>
  );
}

export default FormAdd;
