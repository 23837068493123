import React, { Dispatch, SetStateAction } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import SelectApiInput from "../../../input/SelectApiInput";
import DatetimeInput from "../../../input/DateTimeInput";
import { formatDateDisplay } from "../../../../../utils/helpers";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
  isEdit?: boolean;
  setValue: UseFormSetValue<any>;
  setDetails: Dispatch<SetStateAction<any>>;
}

function InfoSection({
  register,
  setValue,
  setDetails,
  isEdit,
  control,
  errors,
}: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                label="Số CT"
                labelWidth="25%"
                placeholder="Nhập hoặc tạo tự động"
                name="so_ct"
                register={register}
              />
              <Controller
                name="ngay_ct"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatetimeInput
                      required
                      label="Ngày CT"
                      labelWidth="25%"
                      name="ngay_ct"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.ngay_ct?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="pxc"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      labelWidth="25%"
                      label="Phiếu xuất"
                      placeholder="Chọn phiếu xuất chuyển"
                      placeholderSearch="Tìm theo số chứng từ"
                      value={value}
                      onChange={(pxc) => {
                        if (pxc) {
                          setValue("kho_nhap", {
                            ma_kho: pxc?.ma_kho_n || "",
                            ten_kho: pxc?.ten_kho_n || "",
                          });
                          setValue("kho_xuat", {
                            ma_kho: pxc?.ma_kho_x || "",
                            ten_kho: pxc?.ten_kho_x || "",
                          });
                          (pxc?.details || []).forEach((item: any) => {
                            item.sl_xuat_pxc = item.sl_xuat;
                          });
                          setDetails(pxc?.details || []);
                        } else {
                          setValue("kho_nhap", null);
                          setValue("kho_xuat", null);
                          setDetails([]);
                        }
                        onChange(pxc);
                      }}
                      apiCode="pxc"
                      searchFields={["so_ct"]}
                      getOptionLabel={(option) =>
                        option?.so_ct
                          ? `Chứng từ số ${
                              option.so_ct
                            }, ngày ${formatDateDisplay(
                              option.ngay_ct,
                              "DD/MM/YYYY HH:mm"
                            )}`
                          : ""
                      }
                      checkEqual={(value, option) => value._id === option._id}
                      errorMessage={errors?.kho_xuat?.message as string}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <Controller
                control={control}
                name="kho_xuat"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Kho xuất"
                      placeholder="Chọn kho xuất"
                      value={value}
                      onChange={onChange}
                      apiCode="dmkho"
                      searchFields={["ma_kho", "ten_kho"]}
                      getOptionLabel={(option) => option.ten_kho}
                      checkEqual={(value, option) =>
                        value.ma_kho === option.ma_kho
                      }
                      errorMessage={errors?.kho_xuat?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="kho_nhap"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Kho nhập"
                      placeholder="Chọn kho nhập"
                      value={value}
                      onChange={onChange}
                      apiCode="dmkho"
                      searchFields={["ma_kho", "ten_kho"]}
                      getOptionLabel={(option) => option.ten_kho}
                      checkEqual={(value, option) =>
                        value.ma_kho === option.ma_kho
                      }
                      errorMessage={errors?.kho_nhap?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="trang_thai"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Trạng thái"
                      placeholder="Chọn trạng thái"
                      value={value}
                      onChange={onChange}
                      apiCode="trangthai"
                      withIdApp={false}
                      defaultCondition={{ ma_ct: "PNC" }}
                      searchFields={["ma_trang_thai", "ten_trang_thai"]}
                      getOptionLabel={(option) => option.ten_trang_thai}
                      checkEqual={(value, option) =>
                        value.ma_trang_thai === option.ma_trang_thai
                      }
                      errorMessage={errors?.trang_thai?.message as string}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
