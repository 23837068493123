import React, { useEffect, useState } from "react";
import DrawerBase from "../../../../../../components/common/drawer/DrawerBase";
import {
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SelectApiInput from "../../../../../../components/common/input/SelectApiInput";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../../PosPage";
import TotalLine from "../../../../../../components/common/total-line";
import { numeralCustom } from "../../../../../../utils/helpers";
import TienThu from "./TienThu";
import Pttt from "./Pttt";
import FormCustomer from "../../../../../../components/common/form/customer/FormCustomer";
import CheckboxInput from "../../../../../../components/common/input/CheckboxInput";
import ThongTinGiaoHang from "./ThongTinGiaoHang";

type ChietKhauType = {
  tien_ck: number;
  ty_le_ck: number;
};
interface IPaymentInfo {
  open: boolean;
  onClose: () => void;
}

const heights = {
  top: "40px",
  bottom: "50px",
};

const spacinges = {
  py: 1,
  gap: 1,
};

function PaymentInfo({ open, onClose }: IPaymentInfo) {
  const { currentHd } = useSelector((state: any) => state.pos);
  const {
    ship,
    print,
    giamGia,
    setPrint,
    setShip,
    handleUpdateCurrentHd,
    saveHd,
  } = usePosContext();
  const [isPercent, setIsPercent] = useState<boolean>(false);
  const [giaTriChietKhau, setGiaTriChietKhau] = useState<ChietKhauType>({
    tien_ck: 0,
    ty_le_ck: 0,
  });

  const handlePayment = async () => {
    const resp = await saveHd(5);
    if (!!resp) {
      onClose();
    }
  };

  const handleSaveCk = async () => {
    handleUpdateCurrentHd({
      tien_ck_hd: giaTriChietKhau.tien_ck,
      ty_le_ck_hd: giaTriChietKhau.ty_le_ck,
    });
  };

  const handleGiaTriChietKhauChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tienHang = currentHd.t_tien_nt;
    let value = 0;
    if (isPercent) {
      value = +e.target.value;
      if (value > 100) {
        value = 100;
      }
      const tienCk = +((tienHang * value) / 100).toFixed(0);
      setGiaTriChietKhau({ tien_ck: tienCk, ty_le_ck: value });
    } else {
      value = numeralCustom(e.target.value).value() || 0;
      if (value > tienHang) {
        value = tienHang;
      }
      const tyLeCk = (value * 100) / tienHang;
      setGiaTriChietKhau({ tien_ck: value, ty_le_ck: tyLeCk });
    }
  };

  useEffect(() => {
    setGiaTriChietKhau({
      tien_ck: currentHd?.tien_ck_hd || 0,
      ty_le_ck: currentHd?.ty_le_ck_hd || 0,
    });
  }, [currentHd?.tien_ck_hd, currentHd?.ty_le_ck_hd]);

  return (
    <DrawerBase open={open} onClose={onClose} anchor="right">
      <Stack
        sx={{ width: "40vw", height: "100vh", px: 2, py: spacinges.py }}
        gap={spacinges.gap}
        justifyContent="space-between"
      >
        {/* Top */}
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ height: heights.top }}
        >
          {/* Khách hàng */}
          <SelectApiInput
            apiCode="customer"
            searchFields={["ma_kh", "ten_kh", "dien_thoai"]}
            defaultCondition={{ kh_yn: true }}
            getOptionLabel={(option) =>
              `${option.ten_kh}${
                option.dien_thoai ? ` (${option.dien_thoai})` : ""
              }`
            }
            checkEqual={(value, option) => value?.ma_kh === option?.ma_kh}
            value={
              !!currentHd
                ? { ma_kh: currentHd.ma_kh, ten_kh: currentHd.ten_kh }
                : null
            }
            onChange={(val) =>
              handleUpdateCurrentHd({
                ma_kh: val?.ma_kh || "",
                ten_kh: val?.ten_kh || "",
              })
            }
            renderDisplay={({ value, onClick }) => {
              return (
                <Tooltip
                  title={
                    value?.ten_kh ? `Khách hàng: ${value.ten_kh}` : undefined
                  }
                  placement="bottom"
                  arrow
                >
                  <Chip
                    onClick={onClick}
                    label={value?.ten_kh || "Khách hàng"}
                    sx={{ width: "150px", borderRadius: "10px" }}
                  />
                </Tooltip>
              );
            }}
            Form={FormCustomer}
          />
          {/* Nhân viên */}
          <SelectApiInput
            apiCode="dmnv"
            searchFields={["ma_nv", "ten_nv"]}
            getOptionLabel={(option) => option.ten_nv}
            checkEqual={(value, option) => value?.ma_nv === option?.ma_nv}
            value={
              !!currentHd
                ? { ma_nv: currentHd.ma_nv, ten_nv: currentHd.ten_nv }
                : null
            }
            onChange={(val) =>
              handleUpdateCurrentHd({
                ma_nv: val?.ma_nv || "",
                ten_nv: val?.ten_nv || "",
              })
            }
            renderDisplay={({ value, onClick }) => {
              return (
                <Tooltip
                  title={
                    value?.ten_nv ? `Nhân viên: ${value.ten_nv}` : undefined
                  }
                  placement="bottom"
                  arrow
                >
                  <Chip
                    onClick={onClick}
                    label={value?.ten_nv || "Nhân viên"}
                    sx={{ width: "150px", borderRadius: "10px" }}
                  />
                </Tooltip>
              );
            }}
          />
          {/* Kênh bán */}
          <SelectApiInput
            apiCode="dmkenhbanhang"
            searchFields={["ma_kenh", "ten_kenh"]}
            getOptionLabel={(option) => option.ten_kenh}
            checkEqual={(value, option) => value?.ma_kenh === option?.ma_kenh}
            value={
              !!currentHd
                ? { ma_kenh: currentHd.ma_kenh, ten_kenh: currentHd.ten_kenh }
                : null
            }
            onChange={(val) =>
              handleUpdateCurrentHd({
                ma_kenh: val?.ma_kenh || "",
                ten_kenh: val?.ten_kenh || "",
              })
            }
            renderDisplay={({ value, onClick }) => {
              return (
                <Tooltip
                  title={value?.ten ? `Kênh bán: ${value.ten}` : undefined}
                  placement="bottom"
                  arrow
                >
                  <Chip
                    onClick={onClick}
                    label={value?.ten || "Kênh bán"}
                    sx={{ width: "150px", borderRadius: "10px" }}
                  />
                </Tooltip>
              );
            }}
          />
        </Stack>
        <Divider />
        {/* Info */}
        <Stack
          className="hidden-scroll"
          gap={2}
          sx={{
            width: "100%",
            height: `calc(100vh - ${heights.top} - ${heights.bottom} - ${
              spacinges.py * 8 * 2
            }px - ${spacinges.gap * 8 * 2}px)`,
            overflow: "auto",
          }}
        >
          <TotalLine
            label="Tiền hàng"
            count={currentHd?.details?.length}
            value={currentHd?.t_tien_nt}
          />
          <TotalLine
            label="Giảm giá sản phẩm"
            count={giamGia.so_luong}
            value={giamGia.gia_tri}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 600 }}>Giảm giá</Typography>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Button
                onClick={() => setIsPercent(!isPercent)}
                size="small"
                variant="contained"
                sx={{
                  boxShadow: "none",
                  px: 1,
                  py: 0.5,
                  fontSize: "12px",
                  fontWeight: 400,
                  textTransform: "none",
                  minWidth: "unset",
                  color: "common.white",
                  width: "42px",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                {isPercent ? "%" : "VND"}
              </Button>
              {isPercent ? (
                <TextField
                  type="number"
                  variant="standard"
                  autoComplete="off"
                  value={giaTriChietKhau.ty_le_ck}
                  onChange={handleGiaTriChietKhauChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "right",
                      width: "80px",
                    },
                  }}
                  onBlur={handleSaveCk}
                  onKeyUp={(e) => {
                    if (e.which === 13) {
                      handleSaveCk();
                    }
                  }}
                />
              ) : (
                <TextField
                  variant="standard"
                  autoComplete="off"
                  value={numeralCustom(giaTriChietKhau.tien_ck).format()}
                  onChange={handleGiaTriChietKhauChange}
                  onBlur={handleSaveCk}
                  onKeyUp={(e) => {
                    if (e.which === 13) {
                      handleSaveCk();
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "right",
                      width: "80px",
                    },
                  }}
                />
              )}

              <Typography>đ</Typography>
            </Stack>
          </Stack>
          <TotalLine
            label="Khách phải trả"
            value={currentHd?.t_tt_nt}
            valueSx={{ fontSize: "18px", fontWeight: 600, color: "error.main" }}
          />
          <TienThu tongTien={currentHd?.t_tt_nt || 0} />
          <TotalLine
            label="Tiền thừa"
            value={currentHd?.phai_tra}
            valueSx={{
              color: "secondary.main",
              fontSize: "18px",
              fontWeight: 600,
            }}
          />
          <Pttt />
          <CheckboxInput
            label="Hoàn tất và in"
            checked={print}
            onChange={(_, checked) => {
              setPrint(checked);
            }}
          />
          <CheckboxInput
            label="Bán giao hàng"
            checked={ship}
            onChange={(_, checked) => {
              setShip(checked);
            }}
          />
          {ship && <ThongTinGiaoHang />}
        </Stack>
        <Divider />
        {/* Bottom */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: "100%", height: heights.bottom }}
        >
          <Button
            onClick={handlePayment}
            variant="contained"
            color="primary"
            sx={{
              boxShadow: "none",
              color: "common.white",
              "&:hover": { boxShadow: "none" },
            }}
          >
            Hoàn tất
          </Button>
        </Stack>
      </Stack>
    </DrawerBase>
  );
}

export default PaymentInfo;
