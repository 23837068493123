import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import FilterSelectApi from "../FilterSelectApi";
import FilterContainer from "../FilterContainer";
import moment from "moment";
import FilterList from "../FilterList";
import { IFilterProps } from "../../../../types/filter.type";
import { asyncSearchList } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import FilterText from "../FilterText";
import FilterTimeFormTo from "../FilterTimeFromTo";

function FilterPKK({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    so_ct: "",
    vat_tu: null,
    kho: null,
    trang_thai: [],
    timeFrom: "",
    timeTo: moment().format("YYYY-MM-DD"),
  };
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [filter, setFilter] = useState(originFilter);
  const [trangThais, setTrangThais] = useState([]);

  const getTrangThais = async () => {
    const resp = await asyncSearchList({
      apiCode: "trangthai",
      token,
      condition: {
        page: 1,
        limit: 9999999,
        q: { ma_ct: "PKK" },
      },
      withIdApp: false,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải trạng thái",
      });
      return;
    }
    const result = (resp?.data || []).reduce((acc: any[], item: any) => {
      return [
        ...acc,
        { label: item.ten_trang_thai, value: item.ma_trang_thai },
      ];
    }, []);
    setTrangThais(result);
  };

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.so_ct) {
      condition.so_ct = filter.so_ct;
    }
    if (filter.vat_tu) {
      condition.details = {
        $elemMatch: {
          ma_vt: filter.vat_tu.ma_vt,
        },
      };
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if ((filter.trang_thai || []).length > 0) {
      condition.trang_thai = { $in: filter.trang_thai };
    }
    if (filter.timeFrom || filter.timeTo) {
      const endDate = moment(filter.timeTo).hours(23).minutes(59).seconds(59);
      const startDate = moment(filter.timeFrom).hours(0).minutes(0).seconds(0);
      if (filter.timeFrom && filter.timeTo) {
        condition.ngay_ct = { $gte: startDate, $lte: endDate };
      } else if (filter.timeFrom) {
        condition.ngay_ct = { $gte: startDate };
      } else if (filter.timeTo) {
        condition.ngay_ct = { $lte: endDate };
      }
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getTrangThais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Số chứng từ"
          placeholder="Tìm theo số chứng từ"
          value={filter.so_ct}
          onSearch={(value) => setFilter({ ...filter, so_ct: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Sản phẩm"
          apiCode="dmvt"
          placeholder="Chọn Sản phẩm"
          searchFields={["ma_vt", "ten_vt"]}
          value={filter.vat_tu}
          onChange={(val) => setFilter({ ...filter, vat_tu: val })}
          getOptionLabel={(option) => option.ten_vt}
          checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
        />
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          apiCode="dmkho"
          placeholder="Chọn chi nhánh"
          searchFields={["ma_kho", "ten_kho"]}
          value={filter.kho}
          onChange={(val) => setFilter({ ...filter, kho: val })}
          getOptionLabel={(option) => option.ten_kho}
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
        />
        <FilterList
          title="Trạng thái"
          items={trangThais}
          defaultValues={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
        <FilterTimeFormTo
          title="Ngày chứng từ"
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPKK;
