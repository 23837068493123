import React, { useState } from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import moment from "moment";
import InfoSection from "./info-section/InfoSection";
import DetailsSection from "./details-section/DetailsSection";
import DescriptionSection from "./description-section/DescriptionSection";

const schema = yup.object({
  kho_xuat: yup
    .object()
    .typeError("Vui lòng chọn kho xuất")
    .required("Vui lòng chọn kho xuất"),
  kho_nhap: yup
    .object()
    .typeError("Vui lòng chọn kho nhập")
    .required("Vui lòng chọn kho nhập"),
  ngay_ct: yup
    .date()
    .typeError("Vui lòng nhập ngày chứng từ")
    .required("Vui lòng nhập ngày chứng từ"),
  trang_thai: yup
    .object()
    .typeError("Vui lòng chọn trạng thái")
    .required("Vui lòng chọn trạng thái"),
});

function FormPXC({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    so_ct: "",
    ngay_ct: moment().format("YYYY-MM-DD HH:mm"),
    kho_xuat: null,
    kho_nhap: null,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ngay_ct: moment(defaultValues.ngay_ct).format("YYYY-MM-DD HH:mm"),
        kho_xuat: defaultValues?.ma_kho_x
          ? {
              ma_kho: defaultValues?.ma_kho_x,
              ten_kho: defaultValues?.ten_kho_x,
            }
          : null,
        kho_nhap: defaultValues?.ma_kho_n
          ? {
              ma_kho: defaultValues?.ma_kho_n,
              ten_kho: defaultValues?.ten_kho_n,
            }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState<any[]>(defaultValues?.details || []);

  const handleReset = () => {
    reset(originData);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { kho_xuat, kho_nhap, trang_thai, ...data } = values;
    const result = {
      ...data,
      ma_kho_x: kho_xuat?.ma_kho || "",
      ten_kho_x: kho_xuat?.ten_kho || "",
      ma_kho_n: kho_nhap?.ma_kho || "",
      ten_kho_n: kho_nhap?.ten_kho || "",
      trang_thai: trang_thai?.ma_trang_thai || "",
      ten_trang_thai: trang_thai?.ten_trang_thai || "",
      details,
    };
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="1000px"
      formTitle="phiếu xuất chuyển"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
        <DetailsSection details={details} setDetails={setDetails} />
        <DescriptionSection register={register} />
      </Stack>
    </ModalForm>
  );
}

export default FormPXC;
