import React from "react";
import WrapperExpand from "../WrapperExpand";
import { IExpand } from "../../../../types/expand.type";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { asyncDelete } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import TextInput from "../../input/TextInput";
import { formatDateDisplay } from "../../../../utils/helpers";
import TableApp from "../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";

const columns: TableColumn<any>[] = [
  {
    name: "Sản phẩm",
    width: "300px",
    wrap: true,
    selector: (row) => (row.ten_vt ? `${row.ten_vt} (${row.ma_vt})` : ""),
  },
  {
    name: "Tồn sổ sách",
    width: "150px",
    selector: (row) => row.sl_ton_ss,
  },
  {
    name: "Tồn thực tế",
    width: "150px",
    selector: (row) => row.sl_ton_tt,
  },
  {
    name: "Chênh lệch",
    width: "150px",
    selector: (row) => row.sl_xuat,
  },
];

function ExpandPkk({ data, onOpenEdit, setLoad }: IExpand) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();

  const handleDelete = async () => {
    const resp = await asyncDelete({
      apiCode: "pkk",
      id: data?._id,
      token,
      idApp: app?._id,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi xóa dữ liệu",
      });
    } else {
      showAlert({ type: "success", message: `Đã xóa thành công` });
      setLoad?.((prev) => prev + 1);
    }
  };

  return (
    <WrapperExpand
      data={data}
      action={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ pb: 2 }}
        >
          <Button
            onClick={onOpenEdit}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Chỉnh sửa
          </Button>
          <Button
            onClick={() => {
              showAlert({
                title: "Xác nhận xóa",
                type: "info",
                message: "Bạn có chắc muốn xóa dòng này không ?",
                onConfirm: handleDelete,
              });
            }}
            variant="contained"
            size="small"
            color="error"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Xóa
          </Button>
        </Stack>
      }
    >
      <Stack gap={2} sx={{ width: "100%", maxWidth: "1000px" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                readOnly
                labelWidth="25%"
                label="Số CT"
                value={data?.so_ct}
              />
              <TextInput
                readOnly
                labelWidth="25%"
                label="Chi nhánh"
                value={data?.ten_kho}
              />
              <TextInput
                readOnly
                labelWidth="25%"
                label="Ngay CT"
                value={formatDateDisplay(data?.ngay_ct, "DD/MM/YYYY HH:mm")}
              />
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                readOnly
                labelWidth="25%"
                label="Tồn sổ sách"
                value={data?.t_sl_ton_ss}
              />
              <TextInput
                readOnly
                labelWidth="25%"
                label="Tồn thực tế"
                value={data?.t_sl_ton_tt}
              />
              <TextInput
                readOnly
                labelWidth="25%"
                label="Tổng chênh lệch"
                value={data?.t_sl}
              />
            </Stack>
          </Grid2>
        </Grid2>
        {data?.details?.length > 0 && (
          <Stack sx={{ marginTop: "20px" }}>
            <Typography sx={{ fontWeight: 600 }}>Chi tiết đơn hàng:</Typography>
            <Box
              sx={{
                borderRadius: "10px",
                border: "1px solid",
                borderColor: "divider",
                p: 1,
              }}
            >
              <TableApp columns={columns} data={data.details || []} />
            </Box>
          </Stack>
        )}
      </Stack>
    </WrapperExpand>
  );
}

export default ExpandPkk;
