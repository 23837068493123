import React from "react";
import ModalBase from "../../../modal/ModalBase";
import { Avatar, Button, MenuItem, Stack, Typography } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectApiInput from "../../../input/SelectApiInput";
import NumberInput from "../../../input/NumberInput";
import TextInput from "../../../input/TextInput";
import { generateLinkImage, numeralCustom } from "../../../../../utils/helpers";
import { v4 } from "uuid";

interface IFormAdd {
  open: boolean;
  isEdit?: boolean;
  defaultValue?: any;
  add?: (data: any, isEdit: boolean) => void;
  handleClose: () => void;
}

const schema = yup.object<FieldValues>({
  vat_tu: yup
    .object()
    .typeError("Vui lòng chọn Sản phẩm")
    .required("Vui lòng chọn Sản phẩm"),
  don_vi_tinh: yup
    .object()
    .typeError("Vui lòng chọn đơn vị tính")
    .required("Vui lòng chọn đơn vị tính"),
});

function FormAdd({ open, isEdit, defaultValue, add, handleClose }: IFormAdd) {
  const defaultData: { [key: string]: any } = {
    vat_tu: null,
    don_vi_tinh: null,
    gia_ban_nt: 0,
    sl_nhap: 0,
    tien_hang_nt: 0,
    ty_le_ck: 0,
    tien_ck_nt: 0,
    tien_nhap_nt: 0,
  };
  const originData = defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        vat_tu: defaultValue?.ma_vt
          ? {
              ma_vt: defaultValue?.ma_vt || "",
              ten_vt: defaultValue?.ten_vt || "",
            }
          : null,
        don_vi_tinh: defaultValue?.ma_dvt
          ? {
              ma_dvt: defaultValue?.ma_dvt || "",
              ten_dvt: defaultValue?.ten_dvt || "",
            }
          : null,
        detail: defaultValue.ma_vt ? defaultValue : null,
      }
    : defaultData;
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const soLuong = watch("sl_nhap");
  const giaBan = watch("gia_ban_nt");
  const tyLeCk = watch("ty_le_ck");
  const tienHang = watch("tien_hang_nt");

  const generateDataAdd = (values: typeof defaultData) => {
    const { vat_tu, don_vi_tinh, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_dvt: don_vi_tinh?.ma_dvt || "",
      ten_dvt: don_vi_tinh?.ten_dvt || "",
      tk_vt: "1561",
      _id: v4(),
    };
  };

  // handle save
  const handleSave = (values: any) => {
    const dataAdd = generateDataAdd(values);
    add?.(dataAdd, !!isEdit);
    handleClose?.();
  };

  return (
    <ModalBase
      title={`${isEdit ? "Thêm" : "Sửa"} chi tiết`}
      open={open}
      handleClose={handleClose}
      width="500px"
      actions={[
        <Button
          key={1}
          onClick={handleSubmit(handleSave)}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu
        </Button>,
        <Button
          key={2}
          onClick={handleClose}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Stack
        gap={2}
        sx={{ p: 2, maxHeight: "calc(90vh - 100px)", overflow: "auto" }}
      >
        <Controller
          control={control}
          name="vat_tu"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                labelWidth="25%"
                label="Sản phẩm"
                placeholder="Chọn hàng trả"
                selectTitle="Chọn hàng trả"
                placeholderSearch="Tìm theo mã, tên sản phẩm"
                value={value}
                onChange={(val) => {
                  onChange(val);
                  setValue(
                    "don_vi_tinh",
                    val?.ma_dvt
                      ? { ma_dvt: val?.ma_dvt, ten_dvt: val?.ten_dvt }
                      : null
                  );
                }}
                renderOption={(option, { isEqual, key, onClick }) => {
                  return (
                    <MenuItem
                      key={key}
                      onClick={onClick}
                      sx={{
                        gap: 1,
                        backgroundColor: isEqual ? "primary.500" : "",
                      }}
                    >
                      <Avatar
                        src={
                          option?.picture_thumb
                            ? generateLinkImage(option?.picture_thumb)
                            : ""
                        }
                        sx={{ borderRadius: "10px" }}
                      />
                      <Typography>
                        {option.ten_vt} ({option.ma_vt})
                      </Typography>
                    </MenuItem>
                  );
                }}
                apiCode="dmvt"
                searchFields={["ma_vt", "ten_vt"]}
                getOptionLabel={(option) => option.ten_vt}
                checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
                errorMessage={errors?.vat_tu?.message as string}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="don_vi_tinh"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                readOnly
                labelWidth="25%"
                label="Đơn vị tính"
                placeholder="Đơn vị tính tự động theo Sản phẩm"
                value={value}
                onChange={onChange}
                apiCode="dmdvt"
                searchFields={["ma_dvt", "ten_dvt"]}
                getOptionLabel={(option) => option.ten_dvt || option.ma_dvt}
                checkEqual={(value, option) => value?.ma_dvt === option?.ma_dvt}
                errorMessage={errors?.don_vi_tinh?.message as string}
              />
            );
          }}
        />
        <Controller
          name="gia_ban_nt"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                label="Giá bán"
                labelWidth="25%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  const tienHang = (val || 0) * (soLuong || 0);
                  const tienCk = ((tienHang || 0) * (tyLeCk || 0)) / 100;
                  const tienNhap = (tienHang || 0) - (tienCk || 0);

                  setValue("tien_hang_nt", tienHang);
                  setValue("tien_ck_nt", tienCk);
                  setValue("tien_nhap_nt", tienNhap);
                  onChange(val);
                }}
              />
            );
          }}
        />
        <Controller
          name="sl_nhap"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <NumberInput
                label="Số lượng"
                labelWidth="25%"
                value={value}
                onChange={(e) => {
                  const val = Number(e.target.value);
                  const tienHang = (val || 0) * (giaBan || 0);
                  const tienCk = ((tienHang || 0) * (tyLeCk || 0)) / 100;
                  const tienNhap = (tienHang || 0) - (tienCk || 0);

                  setValue("tien_hang_nt", tienHang);
                  setValue("tien_ck_nt", tienCk);
                  setValue("tien_nhap_nt", tienNhap);
                  onChange(val);
                }}
              />
            );
          }}
        />
        <Controller
          name="tien_hang_nt"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                readOnly
                label="Tiền hàng"
                labelWidth="25%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = e.target.value;
                  onChange(numeralCustom(val).value());
                }}
              />
            );
          }}
        />
        <Controller
          name="ty_le_ck"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <NumberInput
                label="Tỷ lệ CK"
                labelWidth="25%"
                value={value}
                onChange={(e) => {
                  const val = +e.target.value;
                  const tienCk = ((tienHang || 0) * (val || 0)) / 100;
                  const tienNhap = (tienHang || 0) - (tienCk || 0)

                  setValue("tien_ck_nt", tienCk);
                  setValue("tien_nhap_nt", tienNhap);
                  onChange(val);
                }}
              />
            );
          }}
        />
        <Controller
          name="tien_ck_nt"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                label="Tiền CK"
                labelWidth="25%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  const tienNhap =
                    tienHang > 0 ? (tienHang || 0) - (val || 0) : 0;
                  const tyLeCk = ((val || 0) * 100) / tienHang;
                  setValue("tien_nhap_nt", tienNhap);
                  setValue("ty_le_ck", tyLeCk);
                  onChange(val);
                }}
              />
            );
          }}
        />
        <Controller
          name="tien_nhap_nt"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                readOnly
                label="Thành tiền"
                labelWidth="25%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = e.target.value;
                  onChange(numeralCustom(val).value());
                }}
              />
            );
          }}
        />
      </Stack>
    </ModalBase>
  );
}

export default FormAdd;
