import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LuPlusCircle, LuTrash } from "react-icons/lu";
import TableApp from "../../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import { cloneDeep, isArray } from "lodash";
import FormAdd from "./FormAdd";
import { formatDateDisplay, numeralCustom } from "../../../../../utils/helpers";
import SelectApiInput from "../../../input/SelectApiInput";

const columns: TableColumn<any>[] = [
  {
    name: "Sản phẩm",
    width: "200px",
    wrap: true,
    selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
  },
  {
    name: "ĐVT",
    width: "100px",
    wrap: true,
    selector: (row) => row.ma_dvt,
  },
  {
    name: "Giá bán",
    wrap: true,
    width: "120px",
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
  },
  {
    name: "SL",
    width: "80px",
    selector: (row) => row.sl_nhap,
  },
  {
    name: "Tiền hàng",
    width: "120px",
    selector: (row) => row.tien_hang_nt,
    format: (row) => numeralCustom(row.tien_hang_nt).format(),
  },
  {
    name: "Tiền CK",
    width: "120px",
    selector: (row) => row.tien_ck_nt,
    format: (row) => numeralCustom(row.tien_ck_nt).format(),
  },
  {
    name: "Thành tiền",
    width: "120px",
    selector: (row) => row.tien_nhap_nt,
    format: (row) => numeralCustom(row.tien_nhap_nt).format(),
  },
];

interface IDetaisSection {
  hd?: any;
  details: any[];
  setDetails: Dispatch<SetStateAction<any>>;
}

function DetailsSection({ details, setDetails }: IDetaisSection) {
  const { showAlert } = useAlertContext();
  const [show, setShow] = useState<boolean>(true);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toggleClear, setToggleClear] = useState(false);

  // add a detail
  const handleAdd = (detail: any, isEdit: boolean = false) => {
    if (!isEdit) {
      const existed = details.find((item) => item.ma_vt === detail.ma_vt);
      if (existed) {
        showAlert({
          type: "warning",
          message: `Sản phẩm '${detail.ten_vt}' đã có trong danh sách`,
        });
        return;
      }
    }
    if (isEdit) {
      const index = details.findIndex((item) => item.ma_vt === detail.ma_vt);
      if (index >= 0) {
        const detailsCopy = cloneDeep(details);
        detailsCopy.splice(index, 1, detail);
        setDetails(detailsCopy);
      }
    } else {
      setDetails([...details, detail]);
    }
  };

  // select from hd
  const selectFromHd = (hd: any) => {
    if (isArray(hd?.details) && hd?.details?.length > 0) {
      const newDetails = (hd?.details || []).map((item: any) => {
        item.tien_nhap_nt = item.tien_xuat_nt;
        item.sl_nhap = item.sl_xuat;
        return item;
      });
      setDetails([...details, ...newDetails]);
    }
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = (rows: any[]) => {
    setSelectedRows(rows);
  };
  // delete detail
  const handleDeleteDetail = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item._id);
    let detailsCopy = cloneDeep(details);
    detailsCopy = detailsCopy.filter((item) => !ids.includes(item._id));
    setDetails(detailsCopy);
  };
  // click on row
  const handleRowClicked = (row: any) => {
    setDefaultValue(row);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseFormAdd = () => {
    setIsEdit(false);
    setDefaultValue(null);
    setOpenForm(false);
  };
  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [details]);

  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          handleClose={handleCloseFormAdd}
          add={handleAdd}
          isEdit={isEdit}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Chi tiết"
        show={show}
        onCollapse={() => setShow(!show)}
      >
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Button
              onClick={() => setOpenForm(true)}
              variant="contained"
              size="small"
              startIcon={<LuPlusCircle size={16} />}
              sx={{
                boxShadow: "none",
                color: "common.white",
                textTransform: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Thêm
            </Button>
            <SelectApiInput
              apiCode="pbl"
              selectTitle="Chọn hóa đơn"
              placeholderSearch="Tìm theo số CT, tên hoặc mã khách hàng"
              searchFields={["so_ct", "ten_kh", "ma_kh"]}
              checkEqual={(option, value) => option?._id === value?._id}
              onChange={selectFromHd}
              getOptionLabel={(option) => option.so_ct}
              renderOption={(option, { key, isEqual, onClick }) => {
                return (
                  <MenuItem
                    key={key}
                    onClick={onClick}
                    sx={{
                      py: 1,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      backgroundColor: isEqual ? "primary.500" : "",
                    }}
                  >
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: 600, color: "secondary.main" }}
                      >
                        Số CT:
                      </Typography>{" "}
                      {option?.so_ct}, ngày:{" "}
                      {formatDateDisplay(option?.ngay_ct, "DD/MM/YYYY HH:mm")},{" "}
                      Giá trị: {numeralCustom(option?.t_tt_nt).format()}
                    </Typography>
                    <Typography>
                      <Typography component="span" sx={{ fontWeight: 600 }}>
                        Khách hàng:
                      </Typography>{" "}
                      {option?.ten_kh || ""}
                    </Typography>
                  </MenuItem>
                );
              }}
              renderDisplay={({ value, onClick }) => {
                return (
                  <Button
                    onClick={onClick}
                    variant="contained"
                    size="small"
                    startIcon={<LuPlusCircle size={16} />}
                    sx={{
                      boxShadow: "none",
                      color: "common.white",
                      textTransform: "none",
                      "&:hover": { boxShadow: "none" },
                    }}
                  >
                    Chọn đơn hàng
                  </Button>
                );
              }}
            />
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: "error.main",
                  color: "common.white",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "error.main",
                  },
                }}
                onClick={handleDeleteDetail}
              >
                <LuTrash size={14} />
              </IconButton>
            )}
          </Stack>
          {details && Array.isArray(details) && details.length > 0 && (
            <Box
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "10px",
                p: 1,
              }}
            >
              <TableApp
                toggleCleared={toggleClear}
                columns={columns}
                data={details}
                selectableRows
                onSelectedRowsChange={handleSelectRowsChange}
                onRowClicked={handleRowClicked}
              />
            </Box>
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default DetailsSection;
